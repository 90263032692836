<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>
                  Grading reports
                </h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard </router-link>\  Finance grading reports
                </div>
              </div>
              <div class="breadcrumb-right">
                <b-dropdown size="sm" variant="link" toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon" no-caret right no-flip>
                  <template v-slot:button-content>
                    <i class="ki ki-bold-more-hor"></i>
                  </template>
                  <!--begin::Navigation-->
                  <div class="navi navi-hover min-w-md-250px">
                    <b-dropdown-text tag="div" class="navi-item">
                      <a @click="exportToCsv()"  class="navi-link">
                                <span class="navi-icon">
                                    <i class="fa fa-file-csv"></i>
                                </span>
                        <span class="navi-text">Export (CSV)</span>
                      </a>
                    </b-dropdown-text>
                  </div>
                  <!--end::Navigation-->
                </b-dropdown>

              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start">
              <div class="row">
                <v-col cols="12" sm="6" md="3">
                  <v-text-field
                      label="Exam Key"
                      v-model="search.exam_key"
                      outlined v-on:keyup.enter="searchReport"
                      dense
                      @input="search.transaction_id = $event !== null ? $event : ''"
                      clearable
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="2">
                  <v-menu
                      ref="menuDate"
                      v-model="menuStartDate"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                          v-model="search.start_date"
                          label="View from date"
                          readonly
                          outlined
                          dense
                          clearable
                          v-bind="attrs"
                          v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                        v-model="search.start_date"
                        outlined
                        dense
                        no-title
                        @input="menuStartDate = false"
                    >
                    </v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" sm="6" md="2">
                  <v-menu
                      ref="menuDate"
                      v-model="menuEndDate"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y  full-width="true"
                      min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                          v-model="search.end_date"
                          label="View up to date"
                          readonly
                          outlined
                          dense

                          v-bind="attrs"
                          v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                        v-model="search.end_date"
                        outlined
                        dense :max="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"

                        no-title
                        @input="menuEndDate = false"
                    >
                    </v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" md="2">
                  <v-autocomplete  v-model="search.month" outlined label="Months"
                             item-text="text" :items="months"
                             item-value="value"  v-on:keyup.enter="searchReport"
                             dense
                             clearable>
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" md="3">
                  <v-autocomplete  v-model="search.year" outlined label="Year"
                             item-text="year" :items="enrolment_years"
                             item-value="year"  v-on:keyup.enter="searchReport" dense clearable>
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" md="2" v-if="currentUser.access_type!='score'">
                  <v-autocomplete
                      :items="scores"
                      v-on:keyup.enter="searchReport"
                      v-model="search.score_id"
                      label="SCORE"
                      item-text="name"
                      item-value="id"
                      outlined
                      dense clearable
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" md="10" class="text-right">
                  <v-btn @click="searchReport()" class="btn btn-primary" style="color: #fff">
                    <v-icon small elevation="2" outlined>fas fa-search</v-icon>&nbsp;
                    Search
                  </v-btn>
                </v-col>
              </div>
            </div>
            <div class="table-responsive">
              <v-skeleton-loader type="table-thead" v-if="loading"></v-skeleton-loader>

              <v-skeleton-loader v-if="loading" type="table-row-divider@25">
              </v-skeleton-loader>
              <table v-if="! loading" class="table">
                <thead>
                    <tr class="text-left">
                      <th class="px-3" style="max-width: 270px !important; white-space: pre-wrap;">Exam key</th>
                      <th class="px-3" style="max-width: 270px !important; white-space: pre-wrap;">Candidate name</th>
                      <th class="px-3" style="max-width: 270px !important; white-space: pre-wrap;">Purchased date</th>
                      <th class="px-3" style="max-width: 270px !important; white-space: pre-wrap;">Expiry date</th>
                      <th class="px-3" style="max-width: 270px !important; white-space: pre-wrap;">Email </th>
                      <th class="px-3" style="max-width: 270px !important; white-space: pre-wrap;">Accountholder name </th>
                      <th class="px-3" style="max-width: 270px !important; white-space: pre-wrap;">Subject name</th>
                      <th class="px-3" style="max-width: 270px !important; white-space: pre-wrap;">Subject code</th>
                      <th class="px-3" style="max-width: 270px !important; white-space: pre-wrap;">Mark obtained</th>
                      <th class="px-3" style="max-width: 270px !important; white-space: pre-wrap;">Instrument</th>
                      <th class="px-3" style="max-width: 270px !important; white-space: pre-wrap;">Syllabus</th>
                      <th class="px-3" style="max-width: 270px !important; white-space: pre-wrap;">Grade</th>
                      <th class="px-3" style="max-width: 270px !important; white-space: pre-wrap;">State</th>
                    </tr>
                </thead>
                <tbody>
                  <template v-if="grading_reports.length != 0">
                    <tr v-for="(item, index) in grading_reports" :key="index">
                      <td class="px-3" style="max-width: 270px !important; white-space: pre-wrap;">
                        <a @click="getCandidateSummary(item.exam_key)" class="text-primary font-weight-bolder text-hover-primary mb-1">
                          {{item.exam_key ? item.exam_key : '-' }}
                        </a>
                      </td>
                      <td class="px-3" style="max-width: 270px !important; white-space: pre-wrap;">
                      <span  class="font-weight-bolder text-dark-75" v-copy="item.candidate_full_name">
                        {{item.candidate_full_name ? item.candidate_full_name : '-' }}
                      </span>
                      </td>
                      <td class="px-3" style="max-width: 270px !important; white-space: pre-wrap;" >
                        <span class="font-weight-medium ">{{item.purchased_date}}</span>
                      </td>
                      <td class="px-3" style="max-width: 270px !important; white-space: pre-wrap;" >
                        <span class="font-weight-medium ">{{item.expiry_date}}</span>
                      </td>
                      <td class="px-3" style="max-width: 270px !important; white-space: pre-wrap;" >
                        <span class="font-weight-medium ">{{item.accountholder_email}}</span>
                      </td>
                      <td class="px-3" style="max-width: 270px !important; white-space: pre-wrap;" >
                        <span class="font-weight-medium ">{{item.accountholder_name }}</span>
                      </td>
                      <td class="px-3" style="max-width: 270px !important; white-space: pre-wrap;" >
                        <span class="font-weight-medium ">{{item.product_name }}</span>
                      </td>
                      <td class="px-3" style="max-width: 270px !important; white-space: pre-wrap;" >
                        <span class="font-weight-medium ">{{item.subject_code }}</span>
                      </td>
                      <td class="px-3" style="max-width: 270px !important; white-space: pre-wrap;" >
                        <span class="font-weight-medium ">{{item.mark_obtained }}</span>
                      </td>

                      <td class="px-3" style="max-width: 270px !important; white-space: pre-wrap;" >
                        <span class="font-weight-medium ">{{item.instrument_name ? item.instrument_name : '-' }}</span>
                      </td>
                      <td class="px-3" style="max-width: 270px !important; white-space: pre-wrap;" >
                        <span class="font-weight-medium ">{{item.syllabus_name }}</span>
                      </td>

                      <td class="px-3" style="max-width: 270px !important; white-space: pre-wrap;" >
                        <span class="font-weight-medium ">{{item.grade_name }}</span>
                      </td>
                     <td class="px-3" style="max-width: 270px !important; white-space: pre-wrap;" >
                        <span class="font-weight-medium ">{{item.state_text }}</span>
                      </td>
                    </tr>
                  </template>
                <template v-else>
                  <tr>
                    <td class="text-center" colspan="13">
                      No report available
                    </td>
                  </tr>
                </template>
                </tbody>
              </table>
              <b-pagination
                  :disabled="loading"
                  v-if="grading_reports.length > 0"
                  class="pull-right mt-7"
                  @input="getAllGradingReport"
                  v-model="page"
                  :total-rows="total"
                  :per-page="perPage"
                  first-number
                  last-number
              ></b-pagination>
            </div>
          </div>
        </div>
      </div>
      <v-overlay :value="report_overlay">
        <v-progress-circular
            indeterminate
            size="64"
        ></v-progress-circular>
      </v-overlay>
    </div>
  </v-app>
</template>
<script>
import ReportService from "@/services/report/ReportService";
import EnrolmentYearService from "@/services/setting/enrolment-year/EnrolmentYearService";
import ScoreService from "@/services/score/score/ScoreService";

const score=new ScoreService();
const enrolmentYear=new EnrolmentYearService();
const report=new ReportService();
export default {
  data(){
    return{
      loading:false,
      grading_reports:[],
      page:'',
      total:'',
      perPage:'',
      search:{
        month:'',
        year:'',
        score_id:'',
        exam_key:'',
        start_date:'',
        end_date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      },
      months:[
        {value:'01',text:'January'},
        {value:'02',text:'February'},
        {value:'03',text:'March'},
        {value:'04',text:'April'},
        {value:'05',text:'May'},
        {value:'06',text:'June'},
        {value:'07',text:'July'},
        {value:'08',text:'August'},
        {value:'09',text:'September'},
        {value:'10',text:'October'},
        {value:'11',text:'November'},
        {value:'12',text:'December'},
      ],
      menuStartDate:false,
      menuEndDate:false,
      currentYear:new Date().getFullYear(),
      enrolment_years:[],
      scores:[],
      report_overlay:false,
    }
  },
  methods:{
    getAllGradingReport(){
      this.loading=true;
        report
            .getGradingReports(this.search,this.page)
            .then(response => {
              this.grading_reports=response.data.data;
              this.page = response.data.meta.current_page;
              this.total = response.data.meta.total;
              this.perPage = response.data.meta.per_page;
            })
            .catch((err) => {

            })
            .finally(()=>{
              this.loading=false;
            });
    },
    exportToCsv(){
      this.report_overlay=true;
      report
          .exportGradingToCsv(this.search)
          .then(response => {
            this.$snotify.success('Export added to queue. You will receive an email upon completion');
          })
          .catch((err) => {
            console.log(err)
          })
          .finally(()=>{
            this.report_overlay=false;
          });
    },
    searchReport(){
      this.page=1;
      this.getAllGradingReport();
    },
    getAllEnrolmentYears() {
      enrolmentYear
          .all()
          .then(response =>{
            this.enrolment_years=response.data;
          })
          .catch((err) => {
            console.log(err)
          })
          .finally(()=>{

          });
    },
    getAllScore(){
      score
          .getScores()
          .then(response => {
            this.scores = response.data.scores;
          })
          .catch((err) => {

          });
    },
    getCandidateSummary(examKey){
      this.$router.push({
        name: 'candidate-summary',
        params: {examKey: examKey}
      })
    },
  },
  mounted() {
    this.getAllGradingReport();
    this.getAllEnrolmentYears();
    this.getAllScore();
  },
  computed:{
    currentUser() {
      return this.$store.getters.currentUser;
    },
  }
}
</script>